@import '../colors.scss';

.content {
	padding: 2em 1em 0;
	background-color: $offwhite;
}

@media (min-width: 48em) {
	.content {
		padding: 2em 3em 0;
		margin-left: 25%;
	}
}
