@import '../colors.scss';

.sidebar {
	background: $blue;
	color: $yellow;
	.header {
		text-align: center;
		top: auto;
		margin: 3em auto;
		img {
			width: 150px;
		}
		.brand-title,
		.brand-tagline {
			margin: 0;
		}
		.brand-title {
			text-transform: uppercase;
		}
		.brand-tagline {
			font-size: 1em;
			font-weight: 300;
			color: $offwhite;
		}
	}
}
@media (min-width: 48em) {
	.sidebar {
		position: fixed;
		top: 0;
		bottom: 0;
		.header {
			margin: 50% 2em 0;
			text-align: right;
		}
	}
}
