@import '../colors.scss';

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#layout {
    padding: 0;
}
